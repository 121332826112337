import TextTemplate from "@/assets/icons/email-admin/icon-text-temp.svg";
import EditGreen from "@/assets/icons/email-admin/icon-editgreen.svg";
import FolderIcon from "@/assets/icons/email-admin/icon-folder.svg";
import TrashRed from "@/assets/icons/email-admin/icon-trash-red.svg";
import ArrowIcon from "@/assets/icons/email-admin/icon-arrow.svg";
import EditIcon from "@/assets/icons/email-admin/icon-edit.svg";
import DownIcon from "@/assets/icons/email-admin/icon-down.svg";
import { format } from "date-fns";
import axios from "@/axios";
import BrowseDir from "@/components/browse-dir";
import emailTempModal from "@/components/emailtemplatemodal";
import renameFolderModal from "@/components/renameFolder";
import dropdown from "@/components/dropdown-base";
import sortIcon from "@/assets/icons/dashboard/dropdown-double.svg";
import Dialog from "@/components/dialog";
import { uniq } from "lodash";
import Loader from "@shared/loader";

export default {
  name: "email-template",
  components: {
    TextTemplate,
    EditGreen,
    TrashRed,
    FolderIcon,
    ArrowIcon,
    EditIcon,
    DownIcon,
    BrowseDir,
    emailTempModal,
    renameFolderModal,
    dropdown,
    sortIcon,
    "dialog-modal": Dialog,
    Loader
  },
  props: {
    isDirModalOpen: Boolean,
    isModalOpen: Boolean,
    onModelClose: Function,
    onDirModalClose: Function
  },
  data() {
    return {
      isLoading: false,
      allTemplates: [],
      rows: [],
      format,
      folderPath: [],
      isTemplateModalOpen: this.isModalOpen,
      isBrowseDirModalOpen: this.isDirModalOpen,
      populateData: {
        id: "",
        content: "",
        description: "",
        path: "/",
        templateName: "",
        templateSubject: "",
        placeholder_metadata: [],
      },
      errorMessage: '',
      directoryToastMessage: null, 
      renameFolder: false,
      folderData: null, 
      filters: {
        name: [],
        description: [],
        created_at: [],
      },
      isError: false,
    };
  },
  computed: {
    columns() {
      return [
        {
          label: "Name",
          field: "name",
          width: "50%",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: this.currentPathTemplates
              ?.map((x) => x.name)
              .filter((x) => x),
            formatValue: this.formatFilterValue,
          },
        },
        {
          label: "Description",
          field: "description",
          width: "30%",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: this.currentPathTemplates
              ?.map((x) => x.description)
              .filter((x) => x),
            formatValue: this.formatFilterValue,
          },
        },
        {
          label: "Date",
          field: "created_at",
          width: "10%",
          filterOptions: {
            enabled: true,
            placeholder: "All",
            filterDropdownItems: uniq(this.currentPathTemplates?.map((x) => {
              if (x?.created_at) {
                return format(new Date(x?.created_at), "dd MMM yyyy")
              }
              return ''
            }).filter((x) => x)),
            formatValue: this.formatFilterValue,
          },
        },
        {
          label: "",
          field: "actions",
          width: "10%",
        },
      ];
    },
    currentPathTemplates() {
      let result = this.allTemplates;
      for (let i = 0; i < this.folderPath.length; i++) {
        result = result[this.folderPath[i].index]?.content;
      }
      return result;
    },
    emailTemplates() {
      let result = this.currentPathTemplates;
      for (let filter in this.filters) {
        if (this.filters[filter]?.length) {
          if (filter === 'created_at') {
            result = result.filter((el) => {
              const date = format(new Date(el[filter]), "dd MMM yyyy");
              return this.filters[filter].includes(date)
            });
          } else
            result = result.filter((el) => this.filters[filter].includes(el[filter]));
        }
      }
      this.rows = result?.map((x) => ({
        ...x,
        created_at: x?.created_at ? format(new Date(x?.created_at), "dd MMM yyyy") : '',
      }));
      return this.rows;
    },
  },
  watch: {
    isModalOpen: function (val) {
      if(val !== this.isTemplateModalOpen) {
        this.isTemplateModalOpen = val;
        this.renameFolder = false;
        this.folderData = null;
      }
    },
    isTemplateModalOpen: function (val) {
      if(this.isModalOpen !== val) {
        this.$emit("onModelClose");
      }
    },
    isDirModalOpen: function (val) {
      if(val !== this.isBrowseDirModalOpen) {
        this.isBrowseDirModalOpen = val;
      }
    },
    isBrowseDirModalOpen: function(val) {
      if(this.isDirModalOpen !== val) {
        this.$emit("onDirModalClose");
      }
      this.directoryToastMessage = null;
    }
  },
  async mounted() {
    if (!this.$store.getters.getTenantId) {
      await this.$store.dispatch("fetchTenantId");
    }
    await this.fetchTemplateList();
  },
  methods: {
    async fetchTemplateList() {
      this.isLoading = true
      this.isError = false;
      try {
        const url = `/email-template/list?external_id=${this.$store.getters.getTenantId}`;
        const result = await axios.get(url);
        let data = result?.data?.content;
        this.allTemplates = data;
      } catch (error) {
        this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
        this.isError = true;
      }
      finally {
        this.isLoading = false

      }
    },
    handleBreadcrumbsPath(index) {
      this.folderPath.splice(index+1);
    },
    handlePath(props) {
      const { ...rest } = props.row;
      if (rest.type === "directory") {
        this.folderPath.push({ ...rest, index: props.index });
      }
    },
    backPath() {
      this.folderPath.pop();
    },
    changeFolderName() {
      const data = this.folderPath[this.folderPath.length - 1];
      this.editclick(data);
    },
    async editclick(data) {
      if (data?.type === "resource") {
        this.renameFolder = false;
        this.folderData = null;
        try {
          const res = await axios.get(`email-template/${data.id}`);
          this.isTemplateModalOpen = true;
          this.populateData = {
            id: res.data.id,
            content: res.data.body,
            path: res.data.path,
            templateName: res.data.name,
            templateSubject: res.data.subject,
            description: res.data.description,
            system_default: data.system_default,
            placeholder_metadata: res.data.placeholder_metadata || [],
          };
        } catch (error) {
          this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
        }
      } else if (data?.type === "directory") {
        this.renameFolder = true;
        this.folderData = data;
        this.folderData.templateName = data.name;
      }
    },
    async deleteclick(id, type) {
      if (id) {
        try {
          await axios.delete(`email-template/${id}`);
          this.$toast.success(type === 'directory' ? 'Folder deleted successfully!': 'Template deleted successfully!');
        } catch (error) {
          this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
        }
        await this.fetchTemplateList();
      }
      if (this.isTemplateModalOpen)
        this.toggleModal();
    },
    async handleCreateFolder(data) {
      this.directoryToastMessage = null;
      try {
        if (data.name.includes("/")) {
          this.directoryToastMessage = { 
            type: "error",
            message: `Folder name can't have / `,
          };
          return ;
        }
        await axios.post("/email-template/create", data);
        // this.$toast.success("Folder created successfully!");
        this.directoryToastMessage = {
          type: "success",
          message: "Folder created successfully!",
        };
      } catch (error) {
        // this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
        this.directoryToastMessage = { 
          type: "error",
          message: error?.response?.data?.detail || "Something went wrong. Please try again",
        };
      }
      await this.fetchTemplateList();
    },
    async saveTemplate(formData) {
      this.errorMessage = '';
      // const getAllFormTemps = (str) => {
      //   return str.match(/{{(.*?)}}/g).map(function(val){
      //     return val.replace(/{{?b}}/g,'');
      //  });
      // }
      let {placeholder_metadata } = formData;
      if (formData && formData.content) {
        let form_tags= (formData.content.match(/{{(.*?)}}/g) || [])
        form_tags = form_tags.length ? form_tags.map(el => el.split('{{')[1].split('}}')[0]) : []
        placeholder_metadata = placeholder_metadata?.filter(ele => !form_tags.includes(ele.name))
      }
      if (formData.templateName) {
        if (formData.templateName.includes("/")) {
          this.directoryToastMessage = { 
            type: "error",
            message: `Template name can't have / `,
          };
          return ;
        }
        try {
          if (formData.id) {
            await axios.post("email-template/update", {
              //tenant id or candidate
              id: formData.id,
              subject: formData.templateSubject,
              body: formData.content,
              name: formData.templateName,
              description: formData.description,
              placeholder_metadata
            });
            this.$toast.success("Template updated successfully!");
          } else {
            await axios.post("email-template/create", {
              //tenant id or candidate
              external_id: this.$store.getters.getTenantId,
              path: `${formData?.path}/${formData.templateName}`.replace(
                "//",
                "/"
              ),
              name: formData.templateName,
              description: formData.description,
              resource_type: "resource",
              subject: formData.templateSubject,
              body: formData.content,
              placeholder_metadata
            });
            this.$toast.success("Template created successfully!");
          }
        } catch (error) {
          this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
        }
        await this.fetchTemplateList();
        this.toggleModal();
      } else {
        this.errorMessage = 'Please enter a template name';
      }
    },
    async saveFolderName(formData) {
      this.errorMessage = '';
      if (formData.templateName) {
        if (formData.templateName.includes("/")) {
          this.directoryToastMessage = { 
            type: "error",
            message: `Folder name can't have / `,
          };
          return ;
        }
        try {
          let path = `${formData?.path}/${formData.templateName}`.replace(
            "//",
            "/"
          );
          await axios.patch(
            `email-template/rename?template_id=${formData.id}&new_name=${formData?.templateName}&new_path=${path}`
          );
          this.$toast.success("Folder renamed successfully!");
        } catch (error) {
          this.$toast.error(error?.response?.data?.detail || "Something went wrong. Please try again");
        }
        await this.fetchTemplateList();
        
        let data = this.folderPath[this.folderPath.length - 1];
        if (data.id === formData.id)
          this.folderPath[this.folderPath.length - 1] = { ...data, ...{
            name: formData.templateName,
            path: `${formData?.path}/${formData.templateName}`.replace(
              "//",
              "/"
            ),
          }};

        this.toggleModal();
      } else {
        this.errorMessage = 'Please enter a folder name';
      }
    },
    toggleModal() {
      this.populateData = {
        id: "",
        content: "",
        description: "",
        path: "/",
        templateName: "",
        templateSubject: "",
        placeholder_metadata: [],
      };
      this.errorMessage = '';
      this.directoryToastMessage = null;
      this.successMessage = '';
      this.isTemplateModalOpen = false;
      this.renameFolder = false;
      this.folderData = null;
    },
    browseToggleModal() {
      this.isBrowseDirModalOpen = !this.isBrowseDirModalOpen;
    },
    formatFilterValue(valuesArray) {
      return valuesArray.map((value) => value.id).join(",");
    },
    updateFilters(column, value) {
      if (this.filters?.[column.field])
        this.filters[column.field] = value;
    }
  },
};
